import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllProducts } from '../api';
import { setAllProducts } from '../context/actions/productActions';
import { CChart } from "@coreui/react-chartjs";

const DBHome = () => {
  const products = useSelector((state) => state.products);
  const dispatch = useDispatch();

  const drinks = products?.filter((item) => item.product_category === 'drinks');
  const desserts = products?.filter((item) => item.product_category === 'desserts');
  const sweets = products?.filter((item) => item.product_category === 'sweet');
  const salts = products?.filter((item) => item.product_category === 'salt');
  const iceCreams = products?.filter((item) => item.product_category === 'icecream');
  const sandwich = products?.filter((item) => item.product_category === 'bread');
  const pizzas = products?.filter((item) => item.product_category === 'pizza');

  useEffect(() => {
    if(!products) {
      getAllProducts().then((data) => {
        dispatch(setAllProducts(data));
      });
    } 
  }, [])
  return (
    <div className='flex items-center justify-center flex-col pt-6 w-full h-full'>
      <div className='grid w-full grid-cols-1 md:grid-cols-2 gap-4 h-full'>
        <div className='flex items-center justify-center'>
          <div className='w-340 md:w-508'>
            <CChart
              type="bar"
              data={{
                labels: [
                  'Bevande', 
                  'Dessert', 
                  'Dolci', 
                  'Salati', 
                  'Gelati', 
                  'Panini', 
                  'Pizza',
                ],
                datasets: [
                  {
                    label: 'Conteggio Categorie',
                    backgroundColor: '#f87979',
                    data: [
                      drinks?.length,
                      desserts?.length,
                      sweets?.length,
                      salts?.length,
                      iceCreams?.length,
                      sandwich?.length,
                      pizzas?.length,
                    ],
                  },
                ],
              }}
              labels="months"
              // options={{
              //   plugins: {
              //     legend: {
              //       labels: {
              //         color: getStyle('--cui-body-color'),
              //       }
              //     }
              //   },
              //   scales: {
              //     x: {
              //       grid: {
              //         color: "red",
              //       },
              //       ticks: {
              //         color: getStyle('--cui-body-color'),
              //       },
              //     },
              //     y: {
              //       grid: {
              //         color: getStyle('--cui-border-color-translucent'),
              //       },
              //       ticks: {
              //         color: getStyle('--cui-body-color'),
              //       },
              //     },
              //   },
              // }}
            />
          </div>
        </div>
        <div className='w-full h-full flex items-center justify-center'>
          <div className='w-275 md:w-460'>
            <CChart
              type="doughnut"
              data={{
                labels: [
                  'Ordini', 
                  'Consegnati', 
                  'Cancellati', 
                  'Pagati',
                  'Da Pagare',
                ],
                datasets: [
                  {
                    backgroundColor: [
                      '#41B883', 
                      '#E46651', 
                      '#00D8FF', 
                      '#DD1B16',
                      '#FF00FB'
                    ],
                    data: [40, 20, 80, 10,50],
                  },
                ],
              }}
              // options={{
              //   plugins: {
              //     legend: {
              //       labels: {
              //         color: getStyle('--cui-body-color'),
              //       }
              //     }
              //   },
              // }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default DBHome