import React from 'react';

const Contact = () => {
  return (
    <div>
        Contatti
    </div>
  )
}

export default Contact