// RoomDetails.js
import React from "react";
import { useParams } from "react-router-dom";
import { rooms } from '../utils/styles';

const RoomDetails = () => {
  const { id } = useParams();

  // Trova la stanza con l'ID corrispondente
  const room = rooms.find(room => room.id === parseInt(id));

  if (!room) {
    return <p>Stanza non trovata</p>;
  }

  return (
    <div className="p-4">
      <h2 className="text-3xl font-bold mb-4">{room.title}</h2>
      <img src={room.imageUrl} alt={room.title} className="w-full h-auto mb-4" />
      <p className="text-lg mb-4">{room.description}</p>
      <p className="text-lg font-bold">{room.price}</p>
    </div>
  );
};

export default RoomDetails;
