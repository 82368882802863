import { motion, AnimatePresence } from 'framer-motion';
import React, { useState, useRef } from 'react';
import { staggerFadeInOut } from '../animations';

const FilterSection = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isLightboxOpen, setIsLightboxOpen] = useState(false);
    const [lightboxImage, setLightboxImage] = useState(null);
    const imageRefs = useRef([]);
    const containerRef = useRef(null);

    const images = [
        { url: 'https://via.placeholder.com/1080', alt: 'Placeholder Image 1', title: 'Foto 1' },
        { url: 'https://via.placeholder.com/256', alt: 'Placeholder Foto 2', title: 'Foto 2' },
        { url: 'https://via.placeholder.com/256', alt: 'Placeholder Foto 3', title: 'Foto 3' },
        { url: 'https://via.placeholder.com/256', alt: 'Placeholder Foto 4', title: 'Foto 4' },
        { url: 'https://via.placeholder.com/256', alt: 'Placeholder Foto 5', title: 'Foto 5' },
        { url: 'https://via.placeholder.com/256', alt: 'Placeholder Foto 6', title: 'Foto 6' },
        { url: 'https://via.placeholder.com/256', alt: 'Placeholder Foto 7', title: 'Foto 7' },
        { url: 'https://via.placeholder.com/256', alt: 'Placeholder Foto 8', title: 'Foto 8' }
    ];

    const handlePrevClick = () => {
        setCurrentIndex((prevIndex) => {
            const newIndex = prevIndex > 0 ? prevIndex - 1 : images.length - 1;
            containerRef.current.scrollBy({ left: -imageRefs.current[0].offsetWidth, behavior: 'smooth' });
            return newIndex;
        });
    };

    const handleNextClick = () => {
        setCurrentIndex((prevIndex) => {
            const newIndex = prevIndex < images.length - 1 ? prevIndex + 1 : 0;
            containerRef.current.scrollBy({ left: imageRefs.current[0].offsetWidth, behavior: 'smooth' });
            return newIndex;
        });
    };

    const openLightbox = (image) => {
        setLightboxImage(image);
        setIsLightboxOpen(true);
    };

    const closeLightbox = () => {
        setIsLightboxOpen(false);
        setLightboxImage(null);
    };

    return (
        <motion.div className='w-full flex flex-col items-start'>

            <div className='relative w-full overflow-hidden'>
                {currentIndex > 0 && (
                    <div className='absolute top-1/2 left-4 transform -translate-y-1/2 z-20 hidden md:block'>
                        <button onClick={handlePrevClick} className='bg-headingColor text-white p-3 rounded-full text-2xl'>
                            &lt;
                        </button>
                    </div>
                )}
                <div ref={containerRef} className='relative w-full flex overflow-x-auto scrollbar-none py-8'>
                    <div className='flex space-x-6'>
                        {images.map((image, i) => (
                            <SliderCard 
                                key={i} 
                                image={image} 
                                index={i}
                                onClick={() => openLightbox(image)}
                                ref={(el) => (imageRefs.current[i] = el)}
                            />
                        ))}
                    </div>
                </div>
                {currentIndex < images.length - 1 && (
                    <div className='absolute top-1/2 right-4 transform -translate-y-1/2 z-20 hidden md:block'>
                        <button onClick={handleNextClick} className='bg-headingColor text-white p-3 rounded-full text-2xl'>
                            &gt;
                        </button>
                    </div>
                )}
            </div>

            <AnimatePresence>
                {isLightboxOpen && (
                    <motion.div 
                        className='fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center'
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                    >
                        <motion.div 
                            className='relative top-10'
                            initial={{ scale: 0.7 }}
                            animate={{ scale: 1 }}
                            exit={{ scale: 0.8 }}
                        >
                            <img src={lightboxImage.url} alt={lightboxImage.alt} className='max-w-full max-h-full' />
                            <button onClick={closeLightbox} className='absolute top-4 right-4 text-white text-5xl'>
                                &times;
                            </button>
                        </motion.div>
                    </motion.div>
                )}
            </AnimatePresence>
        </motion.div>
    );
}

const SliderCard = React.forwardRef(({ image, index, onClick }, ref) => {
    return (
        <motion.div 
            {...staggerFadeInOut(index)}
            className='w-64 cursor-pointer rounded-md bg-headingColor shadow-md flex flex-col items-center justify-center gap-4'
            onClick={onClick}
            ref={ref}
        >
            <img 
                src={image.url} 
                alt={image.alt} 
                className='w-full h-48 object-cover rounded-md'
            />
            <p className='text-xl font-semibold text-primary'>
                {image.title}
            </p>
        </motion.div>
    );
});
    
export default FilterSection;
