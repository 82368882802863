import React, { useEffect } from 'react';
import { DataTable } from '../components';
import { HiCurrencyEuro } from '../assets/icons';
import { useDispatch, useSelector } from 'react-redux';
import { deleteAProduct, getAllProducts } from '../api';
import { setAllProducts } from '../context/actions/productActions';
import { alertNull, alertSuccess } from '../context/actions/alertAction';


const DBItems = () => {
  const products = useSelector((state) => state.products);
  const dispatch = useDispatch();

  useEffect(() => {
    // Carica i prodotti ogni volta che il componente viene montato o aggiornato
    getAllProducts().then(data => {
      dispatch(setAllProducts(data));
    });
  }, [dispatch]);
  

  return (
    <div className='flex items-center justify-self-center gap-4 pt-6 w-full'>
      <DataTable columns={[
        {title: "Immagine", field: "imageURL", render: (rowData) => (
          <img 
            src={rowData.imageURL}
            className='w-32 h16 object-contain rounded-md'
          />
        ),
        }, 
        {
          title: "Nome",
          field: "product_name",
        },
        {
          title: "Categoria",
          field: "product_category",
        },
        {
          title: "Prezzo",
          field: "product_price",
          render: (rowData) => (
            <p className='text-xl font-semibold text-textColor flex items-center justify-center'>
              <HiCurrencyEuro className='text-orange-400 text-4xl'/>
              {parseFloat(rowData.product_price).toFixed(2)}
            </p>
          ),
        },
      ]}
      data={products}
      title="Lista Dei Prodotti"
      actions={[
          {
            icon: "edit",
            tooltip: "Modifica Dati",
            onClick: (event, rowData) => {
              alert("Vuoi Modificare"+ rowData.productId)
            }
          },
          {
            icon: "delete",
            tooltip: "Elimina Dati",
            onClick: (event, rowData) => {
              if (
                window.confirm("Sei sicuro di voler eliminare il prodotto?")
                ) {
                deleteAProduct(rowData.productId).then(res => {
                  dispatch(alertSuccess('Prodotto Eliminato'));
                  setInterval(() => {
                    dispatch(alertNull())
                  },3000);
                  getAllProducts().then(data => {
                    dispatch(setAllProducts(data));
                  });
                });
              }
            }
          },
        ]}
      />

    
      
    </div>
  )
}

export default DBItems