import React, { useEffect } from 'react';
import { Contact, Gallery, Header, Home, Room, Footer } from '../components';
import { useDispatch, useSelector } from 'react-redux';
import { getAllProducts } from '../api';
import { setAllProducts } from '../context/actions/productActions';
import { Route, Routes } from 'react-router-dom';



const Main = () => {
  const products = useSelector((state) => state.products);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!products) {
      getAllProducts().then((data) => {
        dispatch(setAllProducts(data))
      });
    }
  }, []);

  
  return (
    <main className='w-screen min-h-screen flex items-center justify-start flex-col bg-primary'>
      <Header />
      <div className='mt-32 flex flex-col items-start justify-center px-6 md:px-24 2xl:px-96 pb-24'>
      <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/stanze" element={<Room />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/contatti" element={<Contact />} />
      </Routes>
      </div>
      <Footer/>
      
    </main>
  )
}

export default Main
