import axios from "axios";

// export const baseURL = "http://localhost:5001/fullstack-beb-template/us-central1/app";
export const baseURL = "https://us-central1-fullstack-beb-template.cloudfunctions.net/app";


export const validateUserJWTToken = async (token) => {
    try {
        const res = await axios.get(`${baseURL}/api/users/jwtVerification`,{
            headers : {Authorization : "Bearer " + token}
        })
        return res.data.data;
    } catch (err) {
        return null;
    }
};

// Aggiungere Nuovo Prodotto
export const addNewProduct = async (data) => {
    try {
        const res = await axios.post(`${baseURL}/api/products/create`, { ...data });
        return res.data.data;
    } catch (err) {
        return null;
    }
};

// Prendere tutte le info dei prodotti
export const getAllProducts = async () => {
    try {
        const res = await axios.get(`${baseURL}/api/products/all`);
        return res.data.data;
    } catch (err) {
        return null;
    }
};

// eliminare un prodotto
export const deleteAProduct = async (productId) => {
    try {
        const res = await axios.delete(`${baseURL}/api/products/delete/${productId}`);
        return res.data.data;
    } catch (err) {
        return null;
    }
};

// prendi tutti gli utenti
export const getAllUsers = async () => {
    try {
        const res = await axios.get(`${baseURL}/api/users/all`)
        return res.data.data;
    } catch (err) {
        return null;
    }
};

// Add an Item

// Recupera tutti gli ordini per il DB
export const getAllOrder = async () => {
    try {
        const res = await axios.get(
            `${baseURL}/api/products/orders`
            );
        return res.data.data;
    } catch (err) {
        return null;
    }
};

// Recupera tutti gli ordini per il DB
export const updateOrderSts = async (order_id, sts) => {
    try {
        const res = await axios.post(
            `${baseURL}/api/products/updateOrder/${order_id}`,
            null,
            {params: { sts: sts }}
        );
        return res.data.data;
    } catch (err) {
        return null;
    }
};

export const createTableOrder = async (data) => {
    try {
        const res = await axios.post(`${baseURL}/api/products/create-table-order`, { ...data });
        return res.data.data;
    } catch (err) {
        return null;
    }
};


