// RoomCard.js
import React from "react";
import { Link } from "react-router-dom";

const RoomCard = ({ title, imageUrl, price, id }) => {
  return (
    <div className="relative group">
      <img
        src={imageUrl}
        alt={title}
        className="rounded-lg w-full h-48 object-cover transition-transform duration-300 group-hover:scale-105"
      />
      <div className="absolute top-2 left-2 bg-pink-500 text-white text-sm font-semibold px-3 py-1 rounded-lg shadow-lg">
        {price}
      </div>
      <div className="absolute bottom-2 left-2 text-white font-bold text-lg bg-black bg-opacity-50 px-2 py-1 rounded-lg">
        {title}
      </div>
      <Link
        to={`/room/${id}`}
        className="absolute inset-0"
        aria-label={`Dettagli della stanza ${title}`}
      ></Link>
    </div>
  );
};

export default RoomCard;
