import { blueOriginFam } from '../assets';

export const rooms = [
    { id: 1, title: "Blue Origin Farms", imageUrl: blueOriginFam, price: "€50 a Notte", description: "Una bellissima stanza con vista mare." },
    { id: 2, title: "Ocean Land", imageUrl: blueOriginFam, price: "€50 a Notte", description: "Una stanza con ambiente tropicale." },
    { id: 3, title: "Stark House", imageUrl: blueOriginFam, price: "€50 a Notte", description: "Stile moderno e comfort totale." },
    { id: 4, title: "Vinna Villa", imageUrl: blueOriginFam, price: "€50 a Notte", description: "Eleganza e relax in ogni angolo." },
    { id: 5, title: "Bobox", imageUrl: blueOriginFam, price: "€50 a Notte", description: "Accogliente con atmosfera unica." },
];

export const isActiveStyles =
  " text-xl p-2 rounded-md text-headingColor font-semibold text-headingColor px-4 py-2 duration-100 transition-all ease-in-out";
  // p-2 hover:rounded-md hover:bg-orange-500 text-xl hover:text-white hover:scale-105 text-textColor

export const isNotActiveStyles =
  " text-l p-2 hover:rounded-md text-textColor hover:text-headingColor duration-100 px-4 py-2 transition-all ease-in-out";

export const statuses = [
  { id: 1, title: "Bevande", category: "bevande" },
  { id: 2, title: "Dessert", category: "desserts" },
  { id: 3, title: "Primi", category: "primi" },
  { id: 4, title: "Secondi", category: "secondi" },
  { id: 5, title: "Antipasti", category: "antipasti" },
  { id: 6, title: "Contorni", category: "contorni" },
  { id: 7, title: "Gelati", category: "gelati" },
  { id: 8, title: "Panini", category: "panini" },
  { id: 9, title: "Pizza", category: "pizza" },
  { id: 10, title: "Bar", category: "bar" },
];

export const randomData = [
  {
    id: 1,
    imageURL:
    "https://firebasestorage.googleapis.com/v0/b/food-delivery-template-e9526.appspot.com/o/Images%2F1712329059585_d1.png?alt=media&token=3d09aeb8-8521-4a94-8baa-cd1cb3f8cfe6",
    product_name: "Red Bull",
    product_category: "Bevande",
    product_price: "3.00",
  },
  {
    id: 2,
    imageURL:
    "https://firebasestorage.googleapis.com/v0/b/food-delivery-template-e9526.appspot.com/o/Images%2F1712329154271_i3.png?alt=media&token=c01de39c-c148-419a-aa8f-19f6cef09ca2",
    product_name: "Cono Gelato",
    product_category: "Gelati",
    product_price: "3.00",
  },
  {
    id: 3,
    imageURL:
    "https://firebasestorage.googleapis.com/v0/b/food-delivery-template-e9526.appspot.com/o/Images%2F1712329565223_cu1.png?alt=media&token=220b03ea-412e-4dcc-aaf2-07dde40432de",
    product_name: "Cono Gelato",
    product_category: "Gelati",
    product_price: "10.00",
  },
  {
    id: 4,
    imageURL:
    "https://firebasestorage.googleapis.com/v0/b/food-delivery-template-e9526.appspot.com/o/Images%2F1712329114308_f1.png?alt=media&token=4664be4c-5cb0-4944-b1c7-1dee7d26f48f",
    product_name: "Fragole",
    product_category: "Desserts",
    product_price: "3.00",
  },
  {
    id: 5,
    imageURL:
    "https://firebasestorage.googleapis.com/v0/b/food-delivery-template-e9526.appspot.com/o/Images%2F1712329611665_r1.png?alt=media&token=99ddf7c4-f686-478b-a62b-bd3b1df900ea",
    product_name: "Riso",
    product_category: "Primi",
    product_price: "7.00",
  },
  {
    id: 6,
    imageURL:
    "https://firebasestorage.googleapis.com/v0/b/food-delivery-template-e9526.appspot.com/o/Images%2F1712329669492_fi1.png?alt=media&token=ff9944d9-8d5a-4c26-8d08-f2bb2d74ebbd",
    product_name: "Antipasto Mare",
    product_category: "Antipasti",
    product_price: "8.00",
  },
];