import React from "react";
import { Logo } from '../assets';
import { NavLink } from 'react-router-dom';

const Footer = () => {
    return (
        <footer className="bg-white text-green py-10 border-t-2">
            <div className="mx-auto grid grid-cols-1 md:grid-cols-3 gap-8 text-center md:text-center">
                {/* Sezione Resta in Contatto */}
                <div>
                    <h3 className="text-headingColor text-lg font-semibold mb-4">
                        Resta In Contatto
                    </h3>
                    <p className="mb-3">
                        Sede Struttura: B&B Piazza d'Armi
                        <br /> Via Roma, Lamezia Terme (CZ)
                    </p>
                    <p className="mb-3">
                        Email:{" "}
                        <a href="mailto:info@bebpiazzadarmi.it" className="text-headingColor">
                            info@bebpiazzadarmi.it
                        </a>
                    </p>
                    <p className="mb-3">
                        Telefono:{" "}
                        <a href="tel:+390000000" className="text-headingColor">
                            +3900000000
                        </a>
                    </p>
                </div>

                {/* Sezione Link Veloci */}
                <div>
                    <h3 className="text-headingColor text-lg font-semibold mb-4">
                        Link Veloci
                    </h3>
                    <ul>
                        <li className="mb-3">
                            <NavLink to="/" className="hover:text-headingColor">
                                Home
                            </NavLink>
                        </li>
                        <li className="mb-3">
                            <NavLink to="/stanze" className="hover:text-headingColor">
                                Stanze
                            </NavLink>
                        </li>
                        <li className="mb-3">
                            <NavLink to="/gallery" className="hover:text-headingColor">
                                Gallery
                            </NavLink>
                        </li>
                        <li className="mb-3">
                            <NavLink to="/contatti" className="hover:text-headingColor">
                                Contatti
                            </NavLink>
                        </li>
                    </ul>
                </div>

                {/* Sezione Help Center */}
                <div>
                    <h3 className="text-headingColor text-lg font-semibold mb-4">
                        Help Center
                    </h3>
                    <ul>
                        <li className="mb-3">
                            <NavLink to="/termini-e-condizioni" className="hover:text-headingColor">
                                Termini e Condizioni
                            </NavLink>
                        </li>
                        <li className="mb-3">
                            <NavLink to="/cookie-policy" className="hover:text-headingColor">
                                Cookie Policy
                            </NavLink>
                        </li>
                        <li className="mb-3">
                            <NavLink to="/privacy-policy" className="hover:text-headingColor">
                                Privacy Policy
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>

            {/* Sezione Branding */}
            <div className="border-t mt-8 pt-8">
                <div className="text-center">
                    <div className="flex justify-center space-x-4 mt-4">
                        <a href="https://facebook.com" className="hover:text-headingColor">
                            <i className="fab fa-facebook-f"></i>
                        </a>
                        <a href="https://instagram.com" className="hover:text-headingColor">
                            <i className="fab fa-instagram"></i>
                        </a>
                        <a href="https://wa.me" className="hover:text-headingColor">
                            <i className="fab fa-whatsapp"></i>
                        </a>
                    </div>
                </div>
                <NavLink to={"/"} className="flex items-center justify-center gap-4">
                    <img src={Logo} alt="logo" className='w-28' />
                </NavLink>

                {/* Copyright */}
                <div className="text-center mt-8 text-sm text-gray-400">
                    <p>
                        © Copyright 2024 B&B Piazza d'Armi - C.F. e P.IVA
                        00000000
                    </p>
                    <p>Design by Promoty</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
