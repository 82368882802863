import React from 'react';
import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import Logo from '../assets/img/logo.png'; // Importa il logo correttamente

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#F3F4F6',
    padding: '12px',
    
  },
  section: {
    backgroundColor: '#FFFFFF',
    padding: '12px',
    borderRadius: '5px',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    
  },
  title: {
    fontSize: '20px',
    fontWeight: 'bold',
    marginBottom: '16px',
    backgroundColor: "green",
    padding: "5px",
    borderRadius:"6px",
    textAlign:"center",
    color:"white"
  },
  text: {
    fontSize: '14px',
    marginBottom: '10px',
    textAlign:"center"
  },
  textProduct:{
    fontSize: '14px',
    marginBottom: '10px',
    textAlign:"center",
    fontWeight:"bold",
    backgroundColor:"orange",
    color:"white",
    padding:"5px",
    borderRadius:"6px"
  },
  address: {
    marginBottom: '10px',
  },
  logo: {
    width: 80 ,
    height: 80, 
    marginBottom: 20,
  },
});

const OrderPdf = ({ data }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Image src={Logo} style={styles.logo} />
        <Text style={styles.title}>Dettagli Ordine</Text>
        <Text style={styles.text}>Numero Ordine: {data.orderId}</Text>
        <Text style={styles.text}>Data Ordine: {data.created}</Text>
        <Text style={styles.text}>Ordinato a: {data.tipologia}</Text>
        {data.tipologia === "Tavolo" && (
          <Text style={styles.text}>Ordinato al Tavolo: {data.tableNumber}</Text>
        )}
        <Text style={styles.text}>Totale: {(parseFloat(data.total)).toFixed(2)} €</Text>
        {data.shipping_details && Object.values(data.shipping_details.address).some(value => value !== null) && (
          <>
            <Text style={styles.title}>Dettagli Consegna</Text>
            <Text style={styles.text}>{data.shipping_details.name}</Text>
            <Text style={[styles.text, styles.address]}>
              {data.shipping_details.address.line1}
              {data.shipping_details.address.line2 && `, ${data.shipping_details.address.line2}`}
              {data.shipping_details.address.country && `, ${data.shipping_details.address.country}`}
              {data.shipping_details.address.state && `, ${data.shipping_details.address.state}`}
              {data.shipping_details.address.postal_code && ` - ${data.shipping_details.address.postal_code}`}
            </Text>
          </>
        )}
        <Text style={styles.text}>{data.customer.email}</Text>
        <Text style={styles.text}>Tel: {data.customer.phone}</Text>
        {/* Aggiungi altre informazioni della card qui */}
        
        {/* Aggiungi la sezione degli items */}
        <Text style={styles.title}>Prodotti</Text>
        {data.items.map((item, index) => (
          <View key={index}>
            {/* <Text style={styles.textProduct}>Prodotto {index + 1}</Text> */}
            <Text style={styles.text}>Nome Prodotto: {item.product_name}</Text>
            <Text style={styles.text}>Categoria: {item.product_category}</Text>
            <Text style={styles.text}>Quantità: {item.quantity}</Text>
          </View>
        ))}
      </View>
    </Page>
  </Document>
);

export default OrderPdf;
