import React from 'react';
import { useSelector } from 'react-redux';
import { motion } from 'framer-motion';

const Room = () => {
  return (
    <div>
        Stanze
    </div>
  )
}

export default Room