import React from 'react';

const Gallery = () => {
  return (
    <div>
        Gallery
    </div>
  )
}

export default Gallery