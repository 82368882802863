import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllUsers } from '../api';
import { setAllUserDetails } from '../context/actions/allUsersAction';
import DataTable from './DataTable';
import { Avatar } from '../assets';

const DBUsers = () => {
  const allUsers = useSelector((state) => state.allUsers);
  const dispatch = useDispatch();

  useEffect(() => {
    if(!allUsers) {
      getAllUsers().then((data) => {
        dispatch(setAllUserDetails(data))
      });
    }
  }, []);


  return (
    <div className='flex items-center justify-self-center gap-4 pt-6 w-full'>
      <DataTable 
        columns={[
        {
          title: "Immagine", 
          field: "photoURL",
           render: (rowData) => (
          <img 
            src={rowData.photoURL ? rowData.photoURL : Avatar}
            className='w-32 h16 object-contain rounded-md'
          />
        ),
        }, 
        {
          title: "Nome",
          field: "displayName",
        },
        {
          title: "Email",
          field: "email",
        },
        {
          title: "Email Verificata",
          field: "email",
          render: (rowData) => (
            <p 
              className={`px-2 py-1 w-32 text-center text-primary rounded-md ${rowData.emailVerified ? "bg-emerald-500" : "bg-red-500"}`
              }
            >
              {rowData.emailVerified ? "Verificata" : "Non Verificata"}
            </p>
          )
        },
      ]}
      data={allUsers}
      title="Lista Degli Utenti"
      // actions={[
      //     {
      //       icon: "edit",
      //       tooltip: "Modifica Dati",
      //       onClick: (event, rowData) => {
      //         alert("Vuoi Modificare"+ rowData.productId)
      //       }
      //     },
      //     {
      //       icon: "delete",
      //       tooltip: "Elimina Dati",
      //       onClick: (event, rowData) => {
      //         if (
      //           window.confirm("Sei sicuro di voler eliminare il prodotto?")
      //           ) {
      //           deleteAProduct(rowData.productId).then(res => {
      //             dispatch(alertSuccess('Prodotto Eliminato'));
      //             setInterval(() => {
      //               dispatch(alertNull())
      //             },3000);
      //             getAllProducts().then(data => {
      //               dispatch(setAllProducts(data));
      //             });
      //           });
      //         }
      //       }
      //     },
      //   ]}
      />

    
      
    </div>
  )
}

export default DBUsers