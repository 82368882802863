import { motion } from "framer-motion";
import React, { useState } from 'react';
import { buttonClick, staggerFadeInOut } from "../animations";
import { HiCurrencyEuro } from "../assets/icons";
import { getAllOrder, updateOrderSts } from "../api";
import { setOrders } from "../context/actions/ordersAction";
import { useDispatch } from "react-redux";
import { PDFViewer } from '@react-pdf/renderer';
import OrderPdf from './OrderPdf';

const OrderData = ({index, data, admin }) => {

  const dispatch = useDispatch();

  const [isPdfOpen, setIsPdfOpen] = useState(false);

  const handleClick = ( orderId, sts ) => {
    updateOrderSts(orderId, sts).then((response) => {
      getAllOrder().then((data) => {
        dispatch(setOrders(data));
      });
    });
  };

  const togglePdf = () => {
    setIsPdfOpen(!isPdfOpen);
  };
  

  return (
    <motion.div 
      {...staggerFadeInOut(index)}
      className="w-full flex flex-col items-start justify-start px-3 py-2 border relative border-gray-300 bg-lightOverlay drop-shadow-md rounded-md gap-4"
    >
      <div>
        <h1 className="mb-2 text-xl text-headingColor font-semibold">Ordini</h1>
        <div className="flex flex-col md:flex-row justify-between">
          <p className="bg-orange-500 p-2 text-primary rounded-md font-medium">
            Data Ordine: {data.created}
          </p>
          <p className="md:ml-2 bg-orange-500 p-2 mt-2 md:mt-0 text-primary rounded-md font-medium">
            Ordinato a: <span className="bg-neutral-800 p-1 rounded-md font-bold">{data.tipologia}</span>
          </p>
          {data.tipologia === "Tavolo" && (
            <p className="md:ml-2 bg-orange-500 p-2 mt-2 md:mt-0 text-primary rounded-md font-medium">
              Ordinato al Tavolo: <span className="bg-neutral-800 text-l p-2  rounded-md font-bold">{data.tableNumber}</span>
            </p>
          )}
        </div>
      </div>

      <div className="w-full flex flex-col md:flex-col lg:flex-row justify-between items-start">
        <div className="flex items-center gap-4">
          <p className="flex items-center gap-1 text-textColor">
            Totale : <HiCurrencyEuro className="text-lg text-orange-500"/> {""}
            <span className="text-headingColor font-bold">{data?.total}</span>
          </p>
          <p className="px-2 py-[2px] text-sm text-headingColor font-semibold capitalize rounded-md bg-emerald-400 drop-shadow-md">
            {data?.status === "paid" ? "Pagato" : data?.status}
          </p>
          
          <p className={`text-base font-semibold capitalize border border-gray-300 px-2 py-[2px] rounded-md ${
            (data.sts === 'preparing' && "text-orange-500 bg-orange-100") ||
            (data.sts === 'indelivery' && "text-orange-500 bg-orange-100") ||
            (data.sts === 'cancelled' && "text-red-500 bg-red-100") ||
            (data.sts === 'delivered' && "text-emerald-500 bg-emerald-100")  
          }`}>
            {
              data?.sts === 'preparing' ? 'In preparazione' :
              data?.sts === 'indelivery' ? 'In Consegna' :
              data?.sts === 'cancelled' ? 'Annullato' :
              data?.sts === 'delivered' ? 'Consegnato' :
              data?.sts
            }
          </p>
        </div>
        {admin && (
        <div className="flex items-center justify-center md:justify-start gap-2 mt-4 md:mt-0">
          <p className="text-lg font-semibold text-headingColor">
            Cambia a
          </p>
          <select
            className="text-base font-semibold capitalize border border-gray-300 px-2 py-[2px] rounded-md cursor-pointer bg-white"
            value={data.sts}
            onChange={(e) => handleClick(data.orderId, e.target.value)}
          >
            <option value="preparing">In preparazione</option>
            <option value="indelivery">In Consegna</option>
            <option value="delivered">Consegnato</option>
            <option value="cancelled">Annullato</option>
          </select>
        </div>
      )}
      </div>

      <div className="w-full flex flex-col md:flex-row justify-between">
        <div className="flex items-center justify-center md:justify-start gap-4">
          {data?.items && data.items.map((item, j) => (
            <motion.div
              {...staggerFadeInOut(j)}
              key={j}
              className="flex items-center justify-center gap-1"
            >
              <img 
                src={item.imageURL}
                className="w-10 h-10 object-contain"
                alt="immagine-ordine" 
              />
              <div className="flex items-start flex-col">
                <p className="text-base font-semibold text-headingColor">{item.product_name}</p>
                <div className="flex items-start gap-2">
                  <p className="text-sm items-start gap-2">Quantità : {item.quantity}</p>
                  <p className="flex items-center gap-1 text-textColor">
                    <HiCurrencyEuro className="text-2xl text-orange-500" />
                    {parseFloat(item.product_price).toFixed(2)}
                  </p>
                </div>
              </div>
            </motion.div>
          ))}
        </div>

        <div className="w-full md:w-1/2 mt-4 md:mt-0 text-end">
          <h1 className="text-lg text-headingColor font-semibold">{data.shipping_details.name}</h1>
          <p className="text-base text-headingColor">{data.customer.email}<br />Tel:{data.customer.phone}</p>
          <p className="text-base text-headingColor">
            {data.shipping_details.address.line1}
            {data.shipping_details.address.line2}{" "}
            {data.shipping_details.address.country}, {" "}
            {data.shipping_details.address.state} -
            {data.shipping_details.address.postal_code}
          </p>
        </div>
      </div>
      {admin && 
      <motion.button 
        {...buttonClick}
        className="bg-neutral-700 text-white p-2 rounded-md" 
        onClick={togglePdf}>{isPdfOpen ? 'Chiudi PDF' : 'Stampa Ordine'}
      </motion.button>
      }

      {/* Componente PDFViewer condizionalmente renderizzato */}
      {isPdfOpen && (
        <PDFViewer style={{ width: '65%', height: '100vh' }}>
          <OrderPdf data={data} />
        </PDFViewer>
      )}
    </motion.div>
  )
}

export default OrderData;
