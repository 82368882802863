import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { getAllOrder } from '../api';
import { setOrders } from '../context/actions/ordersAction';
import OrderData from './OrderData';

const DBOrders = () => {
  const [searchDate, setSearchDate] = useState('');
  const [searchType, setSearchType] = useState('');
  const orders = useSelector((state) => state.orders);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!orders) {
      getAllOrder().then(data => {
        dispatch(setOrders(data));
      });
    }
  }, [orders, dispatch]);

  // Filtra gli ordini per data
  const filteredOrders = orders ? orders.filter(item => {
    const dateMatches = item.created.includes(searchDate);
    const typeMatches = searchType === '' || item.tipologia === searchType;
    return dateMatches && typeMatches;
  }) : [];

  return (
    <div className='flex flex-col items-center justify-center pt-6 gap-4'>
      <div className="mb-4 w-full md:flex md:items-center md:justify-between">
        <div className="w-full md:w-1/2 md:mr-2 mb-4 md:mb-0">
          <label htmlFor="searchDate" className="block text-xl font-medium text-gray-700">
            Filtra Ordini per Data (gg/mm/aaaa):
          </label>
          <input
            type="text"
            id="searchDate"
            name="searchDate"
            className="mt-1 p-4 block w-full border-gray-300 rounded-md"
            placeholder="Inserisci la data"
            value={searchDate}
            onChange={(e) => setSearchDate(e.target.value)}
          />
        </div>
        <div className="w-full md:w-1/2 md:ml-2">
          <label htmlFor="searchType" className="block text-xl font-medium text-gray-700">
            Filtra per Tipologia:
          </label>
          <select
            id="searchType"
            name="searchType"
            className="mt-1 p-4 block w-full border-gray-300 rounded-md"
            value={searchType}
            onChange={(e) => setSearchType(e.target.value)}
          >
            <option value="">Tutti</option>
            <option value="Domicilio">Domicilio</option>
            <option value="Tavolo">Tavolo</option>
          </select>
        </div>
      </div>
      <div className="w-full">
        {filteredOrders.length > 0 ? (
          filteredOrders.map((item, i) => (
            <OrderData key={i} index={i} data={item} admin={true} />
          ))
        ) : (
          <h1 className='text-[72px] text-headingColor font-bold'>
            Nessun Dato
          </h1>
        )}
      </div>
    </div>
  );
};

export default DBOrders;
