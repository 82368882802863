// Home.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { rooms } from '../utils/styles';
import { heroBanner } from '../assets';
import { buttonClick } from '../animations';
import { FilterSection, RoomCard } from '../components';
import { MdCardTravel, IoCameraSharp, CiLocationOn } from '../assets/icons';
import { motion } from 'framer-motion';

const Home = () => {
    const [travelers, setTravelers] = useState(0);
    const [treasures, setTreasures] = useState(0);
    const [locations, setLocations] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        const incrementNumbers = (target, setter) => {
            let count = 0;
            const interval = setInterval(() => {
                if (count < target) {
                    count += Math.ceil(target / 100);
                    if (count > target) count = target;
                    setter(count);
                } else {
                    clearInterval(interval);
                }
            }, 20);
        };

        incrementNumbers(20409, setTravelers);
        incrementNumbers(150, setTreasures);
        incrementNumbers(200, setLocations);
    }, []);


    return (
        <motion.div className='max-w-7xl grid grid-cols-1 md:grid-cols-1 gap-4 md:pt-20'>
            <div className='w-full grid grid-cols-1 md:grid-cols-2 gap-4 mb-10'>
                <div className='flex flex-col items-start justify-start gap-6'>
                    <p className='text-[40px] text-headingColor md:text-[30px] font-sans font-bold'>
                        Dimentica il lavoro, Rilassati per una Vacanza
                    </p>
                    <p className='text-gray-400 text-lg'>
                        Vivi le Bellezze della nostra cittá e goditi il B&B piu Bello
                    </p>
                    <motion.button {...buttonClick} className='mb-7 bg-headingColor px-6 py-4 rounded-md shadow-md text-primary text-base font-semibold'>
                        Prenota Una Stanza
                    </motion.button>
                    <div className='w-full flex justify-around'>
                        <div className='col-auto text-sm'>
                            <MdCardTravel className='text-4xl text-headingColor' />
                            <span className='text-secondary text-sm font-semibold'>{travelers}</span> Viaggiatori
                        </div>
                        <div className='col-auto'>
                            <IoCameraSharp className='text-4xl text-headingColor' />
                            <span className='text-secondary text-sm font-semibold'>{treasures}+</span> Tesori
                        </div>
                        <div className='col-auto'>
                            <CiLocationOn className='text-4xl text-headingColor' />
                            <span className='text-secondary text-sm font-semibold'>{locations}+</span> Locali da Visitare
                        </div>
                    </div>
                </div>
                <div className='py-2 flex-1 flex items-center justify-end relative'>
                    <img src={heroBanner} alt="heroBanner" />
                </div>
            </div>

            <div className="mt-10">
                <h2 className='text-2xl font-bold text-headingColor mb-6'>Stanze Disponibili</h2>
                <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'>
                    {rooms.map((room) => (
                        <div key={room.id} className="cursor-pointer">
                            <RoomCard {...room} className="w-full md:w-1/2 lg:w-1/3" />
                        </div>
                    ))}
                </div>
            </div>


            <div className='flex flex-col items-start justify-start gap-1'>
                <p className='text-2xl text-headingColor font-bold mt-10'>
                    Gallery
                </p>
            </div>
            <FilterSection />
        </motion.div>
    );
};

export default Home;
